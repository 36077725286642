<template>
  <div class="info-dis-nav">
    <NavBar
      title="信息披露"
      left-arrow
      fixed
      @click-left="$router.go(-1)"
      placeholder
    />
    <div class="nav-text">
      《网络借贷信息中介机构业务活动管理暂行办法》第四条、第九条、第三十条、第三十一条均对于网络借贷信息中介机构的信息披露义务进行了规定。根据《网络借贷信息中介机构业务活动信息披露指引》的规定，理财范平台从机构备案信息、组织信息、审核信息、经营信息、重大事项、项目信息（请见具体借款标的页面）等方面进行信息披露，供用户查看监督。
    </div>
    <ul class="nav-list">
      <li v-for="(item, index) in navList" :key="index" class="nav-option">
        <router-link v-bind:to="`/info_dis_con/${index}`"
          >{{ item.btnText }} <i class="icon"></i
        ></router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { NavBar } from 'vant'
import navList from './nav'

export default {
  name: 'InfoDisNav',
  components: {
    NavBar,
  },
  data() {
    return {
      navList,
    }
  },
}
</script>
<style>
.info-dis-nav {
  /*padding-top: 2em;*/
}
.info-dis-nav .nav-text {
  padding-top: 32px;
  padding-bottom: 16px;
  color: #666;
  font-size: 16px;
  line-height: 32px;
  width: 90%;
  margin: 0 auto;
}
.info-dis-nav .nav-list {
  width: 90%;
  margin: 0 auto;
}
.info-dis-nav .nav-option {
  height: 54px;
  line-height: 54px;
  font-size: 18px;
  border-bottom: 1px solid #999;
  color: #666;
  position: relative;
  z-index: 10;
}
.info-dis-nav .nav-option a {
  color: #171717;
  display: block;
}
.info-dis-nav .nav-option .icon {
  width: 16px;
  height: 16px;
  background: url('./img/arrow.png') no-repeat center;
  background-size: 90%;
  position: absolute;
  right: 0%;
  top: 50%;
  margin-top: -8px;
}
.fade-enter-active {
  transition: opacity 0.3s;
}
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter {
  opacity: 0.2;
}
.fade-leave-active {
  opacity: 0.1;
}
</style>
